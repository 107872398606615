<template>
  <div class="app-container">
    <Title :data="data"></Title>
    <div style="display: flex">
      <Batch
        title="教师总结"
        text="教师总结"
        :list="form.teacher_summary"
        :isBtn="true"
        style="margin-bottom: 20px"
        @refresh="getDetail()"
      />
    </div>
    <div class="bottom_btn">
      <el-button @click="$router.go(-1)">返回</el-button>
      <!-- <el-button type="primary" @click="submit" :loading="btnLoading"
        >提交</el-button
      > -->
    </div>
  </div>
</template>

<script>
import Batch from "@/components/Batch/index.vue";
import batchadd from "@/components/Batch_add/index.vue";
import request from "@/utils/request";
export default {
  components: {
    Batch,
    batchadd,
  },
  data() {
    return {
      selected: false,
      btnLoading: false,
      list: [],
      form: {},
      listQuery: {
        type: 5,
        class_course_id: "",
      },
    };
  },
  created() {
    this.listQuery.type = this.$route.query.type;
    this.listQuery.class_course_id = this.$route.query.class_course_id;
    this.data = JSON.parse(this.$route.query.data);
    localStorage.setItem("classdata", JSON.stringify(this.data));
    this.form = {
      teacher_summary: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "teacher_summary",
        data: [],
      },
    };
    this.getDetail();
  },
  methods: {
    getDetail() {
      request({
        url: "/api/schoolend/eduAdmin/dataDetail",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        if (response.data) {
          this.form = response.data;
          this.$forceUpdate(); //强制更新
        }
      });
    },
    submit() {
      this.btnLoading = true;
      request({
        url: "/api/schoolend/eduAdmin/dataStore",
        method: "post",
        data: this.form,
      })
        .then((response) => {
          this.btnLoading = false;
          this.$message({
            type: "success",
            message: "操作成功",
          });
          // this.getDetail();
          this.$router.go(0);
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.content {
  width: 50%;
  margin: 0 auto;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }
}
.bottom_btn {
  display: flex;
  justify-content: center;
}
</style>
